<template>
  <div>
    <base-header class="pb-6 content__title content__title--calendar">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
          <!-- <a href="#" @click.prevent="prev" class="fullcalendar-btn-prev btn btn-sm btn-default">
            <i class="fas fa-angle-left"></i>
          </a>
          <a href="#" @click.prevent="next" class="fullcalendar-btn-next btn btn-sm btn-default">
            <i class="fas fa-angle-right"></i>
          </a>
          <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'dayGridMonth'}"
                       @click="changeView('dayGridMonth')">
            Month
          </base-button>
          <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'dayGridWeek'}"
                       @click="changeView('dayGridWeek')">
            Week
          </base-button>
          <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'timeGridDay'}"
                       @click="changeView('timeGridDay')">
            Day
          </base-button> -->
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6 xxx">
      <div class="row">
        <div class="col">
          <!-- Fullcalendar -->
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <h5 class="h3 mb-0">{{$t('message.chat')}}</h5>
            </div>
            <!-- Card body -->
            <div class="card-body">
               <chat-comments
                  :fixtures="{
                    project_id: $route.params.id,
                    position_id: null,
                    type: 'CHAT',
                  }"
                  :auto-refresh="true"
                />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import Modal from '@/components/Modal'
  import ChatComments from '@/components/ApaComments/ChatComments.vue'

  import AddBar from './AddBar'
  import { mapGetters } from 'vuex'

  const today = new Date();
  const y = today.getFullYear();
  const m = today.getMonth();
  const d = today.getDate();
  export default {
    name: 'calendar',
    components: {
      Modal,
      AddBar,
      ChatComments
    },
    computed: {
      ...mapGetters([
        'tasks'
      ])
    },
    data() {
      return {

      }
    },
    methods: {
      addTask(task) {
        this.$store.dispatch('addTask', task)
      }
    }
  };
</script>
<style lang="scss">
  @import "~@fullcalendar/core/main.css";
  @import '~@fullcalendar/daygrid/main.css';
  @import '~@fullcalendar/timegrid/main.css';
  @import "~@/assets/sass/core/vendors/fullcalendar";
</style>
