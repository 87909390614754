<template>
  <base-input 
    v-if="task"
    prepend-icon="fas fa-plus"
    :placeholder="$t('message.add_comment')"
    v-model="task.title"
    v-on:keyup.enter="onAdd"></base-input>
</template>
<script>
 
  import { mapGetters } from 'vuex'

  const taskModel = {
          title: '',
          isImportant: false,
          done: false,
          deadline: '',
          color: '#000'
        }

  export default {
    name: 'addbar',
    components: {},
    computed: {
      ...mapGetters([
        'tasks'
      ])
    },
    data() {
      return {
        task: null
      }
    },
    mounted () {
      this.task = Object.assign({}, taskModel)
    },
    methods: {
      onAdd () {
        this.$emit('add', this.task)
        this.task = Object.assign({}, taskModel)
      }
    }
  };
</script>

